@use "uswds-core" as *;

.usa-memorable-date {
  display: flex;

  [type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      /* stylelint-disable-line declaration-no-important */
      pointer-events: none;
      height: 0;
      width: 0;
      margin: 0;
    }
  }

  .usa-form-group {
    margin-top: units(2);
  }
}

.usa-form-group--day,
.usa-form-group--month,
.usa-form-group--year {
  @include u-flex(auto);
  margin-right: units(2);
  width: fit-content;
}

// Support months that include a text input...
.usa-form-group--day input,
.usa-form-group--month input {
  width: units(6);
}

// ...and support months that include a select
.usa-form-group--month select {
  width: units(card-lg);
}

.usa-form-group--year input {
  width: units(9);
}

